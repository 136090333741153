import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, input } from '@angular/core';
import { combineLatest, switchMap } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import QrCodeWithLogo from 'qrcode-with-logos';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { CornerType, DotType, ErrorCorrectionLevel, Logo } from 'qrcode-with-logos/types/src/core/types';

export type LogoOptions = Logo | string;

export type DotOptions = {
  type?: DotType;
  color?: string;
};

export type CornerOptions = {
  type?: CornerType;
  color?: string;
  radius?: number | {
    inner: number;
    outer: number;
  };
};

@Component({
  selector: 'core-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrcodeComponent {

  public readonly content = input.required<string>();
  public readonly size = input<number>(200);
  public readonly margin = input<number>(1);
  public readonly scale = input<number>(1);
  public readonly errorCorrectionLevel = input<ErrorCorrectionLevel | undefined>();
  public readonly logo = input<LogoOptions | undefined>();
  public readonly dots = input<DotOptions>({
    type: 'square',
  });
  public readonly corners = input<CornerOptions>({
    type: 'square',
  });

  private readonly builder$ = combineLatest([
    toObservable(this.content),
    toObservable(this.size),
    toObservable(this.margin),
    toObservable(this.scale),
    toObservable(this.errorCorrectionLevel),
    toObservable(this.logo),
    toObservable(this.dots),
    toObservable(this.corners),
  ]).pipe(
    map(([
      content,
      width,
      margin,
      scale,
      errorCorrectionLevel,
      logo,
      dotsOptions,
      cornersOptions,
    ]) => new QrCodeWithLogo({
      nodeQrCodeOptions: {
        margin,
        scale,
        errorCorrectionLevel,
      },
      content,
      width,
      logo,
      dotsOptions,
      cornersOptions,
    })),
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly elementRef: ElementRef,
  ) {
    this.builder$.pipe(
      switchMap((properties) => {
        return fromPromise(properties.getCanvas());
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((qrCode) => {
      qrCode.style.width = '100%';
      qrCode.style.height = '100%';

      this.elementRef.nativeElement.innerHTML = '';
      this.elementRef.nativeElement.appendChild(qrCode);
    });
  }

}
