import { Component, input } from '@angular/core';
import { QrcodeComponent as CoreQrcodeComponent } from '@core/lib/components/qrcode/qrcode.component';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
  imports: [
    CoreQrcodeComponent,
  ],
})
export class QrcodeComponent {

  public readonly content = input.required<string>();
  public readonly size = input<number>(200);

}
