@let menuList = specialMenus();
@let active = activeMenu();

<div
  class="back-navigation"
  coreActivitySection="SpecialOffer"
  coreUserActivityClickTracking="CloseSpecialOffer"
  routerLink="/main/home"
>
  <img src="/images/back_button.svg" alt="">
</div>

@if (menuList && menuList.length > 1) {
  <div class="menus" coreActivitySection="SelectLanguage">
    @for (menu of menuList; track menu.id) {
      <div
        class="menus__name"
        (click)="activeMenu.set(menu)"
        [coreUserActivityClickTracking]="menu.name.split(' ').join('') + 'OpenSpecialOffer'"
      >
        {{ 'languages.'+menu.language+'.label' | translate }}
      </div>
    }
  </div>
}

@if (active) {
  <div class="menu" coreActivitySection="Menu">
      @for (url of active.pagesLocal; track url) {
        <pinch-zoom [properties]="pinchZoomProperties">
          <img class="page" [src]="url" loading="lazy" alt="">
        </pinch-zoom>
      }
  </div>
}
