<core-qrcode
  [content]="content()"
  [size]="size()"
  [logo]="{
    src: '/images/logo-qr.jpg',
    borderWidth: 1,
    borderRadius: 60,
    logoRadius: 20
  }"
  [dots]="{
    type: 'dot',
    color: '#cc0830',
  }"
  [corners]="{
    type: 'rounded',
    color: '#cc0830',
    radius: {
      inner: 400,
      outer: 0
    }
  }"
/>
